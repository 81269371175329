.contentBlocked {
  height: 100%;
  text-align: center;
  justify-content: center;
	align-items: center;
  background-color: $gray-9;
}

.contentBlocked-header {
  font-size: 30px;
  padding-top: 30px;
}

.contentBlocked-header-small {
  font-size: 15px;
  padding-top: 5px;
}

.contentBlocked-icon {
  margin-top: 20px;
}

.contentBlocked-icon svg {
  width: 100px;
  height: 100px;
}

.contentBlocked-message {
  font-size: 20px;
  margin-top: 20px;
}

.contentBlocked-message-small {
  font-size: 13px;
  margin-top: 5px;
}

.youtubeBlocked-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.youtubeBlocked-container.inside-editor-insertVideoModal {
  height: 80%;
}

.youtubeBlocked-content {
  margin-left:auto;
  margin-right:auto;
  border-radius:0.5rem;
  background-color: $blue-lighter;
  padding-left:1rem;
  padding-right:1rem;
  padding-bottom:0.5rem;
  padding-top:0.5rem;
  text-align:center;
}

.youtubeBlocked-content.inside-slideChoiceMedia-video {
  background-color: $gray-14;
  padding-top: 2rem;
}

.youtubeBlocked-icons {
  margin-bottom:1rem;
  display:flex;
  justify-content:center;
}

.youtubeBlocked-icons .cookieIcon {
  margin-right :1rem;
  width: 3rem;
  color: $green;
}

.youtubeBlocked-icons.inside-editor-insertVideoModal .cookieIcon {
  width: 5rem;
}


.youtubeBlocked-icons .videoIcon {
  margin-right :1rem;
  width: 4.5rem;
  color: $gray-8;
}

.youtubeBlocked-icons .youtubeImageIcon {
  width: 6.5rem;
  background-image: url("https://s3.amazonaws.com/assets.plickers.com/react-assets/images/yt_logo_rgb_light.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.youtubeBlocked-icons .youtubeImageIcon.inside-editor-insertVideoModal {
  width: 10rem;
}

.youtubeBlocked-text {
  margin-bottom: 1.75rem;
  font-size: 1rem;
  color: $textDark
}
.youtubeBlocked-text.inside-editor-insertVideoModal{
  font-size: 1.5rem;
}
.youtubeBlocked-text-simple {
  color:rgb(55,65,81);
}
